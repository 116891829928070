import React, { useEffect, useState, ElementType } from "react";

interface TypingTextProps {
    text: string;
    typingSpeed?: number;
    as?: ElementType;
    className?: string;
}

const TypingText: React.FC<TypingTextProps> = ({
    text,
    typingSpeed = 60,
    as: Tag = "p",
    className = "",
}) => {
    const [displayedText, setDisplayedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setDisplayedText("");
        setCurrentIndex(0);
    }, [text]);

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedText((prev) => prev + text[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
            }, typingSpeed);

            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, typingSpeed, text]);

    return <Tag className={className}>{displayedText}</Tag>;
};

export default TypingText;
