import React, { useState, useEffect, useRef, useCallback } from "react";

interface FleeingTextProps {
    text: string;
}

const FleeingText: React.FC<FleeingTextProps> = ({ text }) => {
    const [position, setPosition] = useState({ top: 100, left: 100 });
    const [isFleeing, setIsFleeing] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);

    const flee = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (isFleeing && textRef.current) {
                const textRect = textRef.current.getBoundingClientRect();

                // Get the mouse/touch position
                const mouseX =
                    e instanceof MouseEvent
                        ? e.clientX
                        : (e as TouchEvent).touches[0].clientX;
                const mouseY =
                    e instanceof MouseEvent
                        ? e.clientY
                        : (e as TouchEvent).touches[0].clientY;

                // Calculate distances
                const deltaX = textRect.left + textRect.width / 2 - mouseX;
                const deltaY = textRect.top + textRect.height / 2 - mouseY;

                // Calculate fleeing offsets
                const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
                const fleeDistance = 100; // Distance to move away from the mouse
                const offsetX = (deltaX / distance) * fleeDistance;
                const offsetY = (deltaY / distance) * fleeDistance;

                // New position clamped within viewport bounds
                const newTop = Math.max(
                    0,
                    Math.min(
                        window.innerHeight - textRect.height,
                        textRect.top + offsetY
                    )
                );
                const newLeft = Math.max(
                    0,
                    Math.min(
                        window.innerWidth - textRect.width,
                        textRect.left + offsetX
                    )
                );

                setPosition({ top: newTop, left: newLeft });
            }
        },
        [isFleeing, textRef]
    );

    const handleMouseEnter = () => {
        setIsFleeing(true);
    };

    const handleMouseDown = () => {
        setIsFleeing(false);
    };

    useEffect(() => {
        if (isFleeing) {
            document.addEventListener("mousemove", flee);
        } else {
            document.removeEventListener("mousemove", flee);
        }
        return () => {
            document.removeEventListener("mousemove", flee);
        };
    }, [isFleeing, flee]);

    return (
        <div
            ref={textRef}
            style={{
                position: "absolute",
                top: position.top,
                left: position.left,
                transition: "top 0.1s, left 0.1s",
                cursor: "pointer",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseDown={handleMouseDown}
        >
            <p className="italic">{text}</p>
        </div>
    );
};

export default FleeingText;
