import { useTranslation } from "react-i18next";
import TypingText from "./TypingText";

export default function Hero() {
    const { t } = useTranslation();

    return (
        <section className="py-40 grid grid-cols-7">
            {/* Section header */}
            <div className="col-start-2 col-span-4 max-w-lg px-5 pb-12 md:pb-16 h-72">
                <TypingText
                    text={t("titel")}
                    as="h1"
                    className="text-7xl mb-4 text-left bottom-0"
                />
            </div>
        </section>
    );
}
