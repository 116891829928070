export default function Features() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Digitalisering for små virksomheder</h2>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none" data-aos-id-blocks>

            {/* 1st item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <path className="stroke-current text-purple-100" d="M 1 4.070312 L 1 16.121094 C 1 18.132812 4.332031 19.648438 8.75 19.648438 C 9.167969 19.648438 9.589844 19.636719 10 19.605469 C 10.285156 19.585938 10.507812 19.335938 10.488281 19.042969 C 10.46875 18.753906 10.21875 18.539062 9.925781 18.554688 C 9.539062 18.578125 9.144531 18.597656 8.75 18.597656 C 4.804688 18.597656 2.054688 17.289062 2.054688 16.121094 L 2.054688 13.929688 C 3.375 14.964844 5.820312 15.632812 8.75 15.632812 C 9.015625 15.632812 9.285156 15.628906 9.546875 15.613281 C 9.839844 15.601562 10.0625 15.355469 10.050781 15.066406 C 10.039062 14.773438 9.792969 14.550781 9.5 14.5625 C 9.25 14.574219 8.996094 14.578125 8.75 14.578125 C 4.804688 14.578125 2.054688 13.273438 2.054688 12.105469 L 2.054688 9.917969 C 3.371094 10.953125 5.820312 11.617188 8.75 11.617188 C 13.164062 11.617188 16.496094 10.101562 16.496094 8.089844 L 16.496094 4.070312 C 16.496094 2.058594 13.164062 0.542969 8.75 0.542969 C 4.332031 0.539062 1 2.058594 1 4.070312 Z M 8.75 10.5625 C 4.804688 10.5625 2.054688 9.253906 2.054688 8.085938 L 2.054688 5.894531 C 3.371094 6.929688 5.820312 7.597656 8.75 7.597656 C 11.675781 7.597656 14.125 6.929688 15.441406 5.894531 L 15.441406 8.085938 C 15.445312 9.257812 12.695312 10.5625 8.75 10.5625 Z M 15.445312 4.070312 C 15.445312 5.242188 12.695312 6.542969 8.753906 6.542969 C 4.808594 6.542969 2.054688 5.238281 2.054688 4.070312 C 2.054688 2.898438 4.804688 1.589844 8.75 1.589844 C 12.691406 1.589844 15.445312 2.894531 15.445312 4.070312 Z M 15.445312 4.070312 " strokeWidth="1"/>
              </svg>
              <h4 className="h4 mb-2">Dataløsninger</h4>
              <p className="text-lg text-gray-400 text-center">Vi afklarer sammen din virkomheds databehov, og jeg opbygger en dataløsning samt integrationer hertil, så data kan skabe værdi</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <circle className="fill-current text-purple-600" cx="32" cy="32" r="32" />
                <path className="stroke-current text-purple-100" strokeWidth="2" strokeLinecap="square" d="M21 23h22v18H21z" fill="none" fillRule="evenodd" />
                <path className="stroke-current text-purple-300" d="M26 28h12M26 32h12M26 36h5" strokeWidth="2" strokeLinecap="square" />
              </svg>
              <h4 className="h4 mb-2">Automatisering</h4>
              <p className="text-lg text-gray-400 text-center">Hjælp til automatisering af repetitive administrationsopgaver, så der spares tid i hverdagen</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g transform="translate(21 21)" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
                  <ellipse className="stroke-current text-purple-300" cx="11" cy="11" rx="5.5" ry="11" />
                  <path className="stroke-current text-purple-100" d="M11 0v22M0 11h22" />
                  <circle className="stroke-current text-purple-100" cx="11" cy="11" r="11" />
                </g>
              </svg>
              <h4 className="h4 mb-2">Udvikling</h4>
              <p className="text-lg text-gray-400 text-center">Produktion af IT løsninger til virksomhedens arbejdsgange eller produkter</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g transform="translate(22 21)" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
                  <path className="stroke-current text-purple-100" d="M17 22v-6.3a8.97 8.97 0 003-6.569A9.1 9.1 0 0011.262 0 9 9 0 002 9v1l-2 5 2 1v4a2 2 0 002 2h4a5 5 0 005-5v-5" />
                  <circle className="stroke-current text-purple-300" cx="13" cy="9" r="3" />
                </g>
              </svg>
              <h4 className="h4 mb-2">Behovsafklaring</h4>
              <p className="text-lg text-gray-400 text-center">En gennemgang af virksomhedens virke og behov som udmunder i forbedringsforslag med fokus på IT</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="400" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
                  <path className="stroke-current text-purple-100" d="M29 42h10.229a2 2 0 001.912-1.412l2.769-9A2 2 0 0042 29h-7v-4c0-2.373-1.251-3.494-2.764-3.86a1.006 1.006 0 00-1.236.979V26l-5 6" />
                  <path className="stroke-current text-purple-300" d="M22 30h4v12h-4z" />
                </g>
              </svg>
              <h4 className="h4 mb-2">Sikkerhed</h4>
              <p className="text-lg text-gray-400 text-center">Styrkelse af IT sikkerhed gennem simple men effektive tiltag som hurtigt kan implementeres</p>
            </div>

            {/* 6th item */}
            <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-delay="500" data-aos-anchor="[data-aos-id-blocks]">
              <svg className="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <rect className="fill-current text-purple-600" width="64" height="64" rx="32" />
                <g transform="translate(21 22)" strokeLinecap="square" strokeWidth="2" fill="none" fillRule="evenodd">
                  <path className="stroke-current text-purple-300" d="M17 2V0M19.121 2.879l1.415-1.415M20 5h2M19.121 7.121l1.415 1.415M17 8v2M14.879 7.121l-1.415 1.415M14 5h-2M14.879 2.879l-1.415-1.415" />
                  <circle className="stroke-current text-purple-300" cx="17" cy="5" r="3" />
                  <path className="stroke-current text-purple-100" d="M8.86 1.18C3.8 1.988 0 5.6 0 10c0 5 4.9 9 11 9a10.55 10.55 0 003.1-.4L20 21l-.6-5.2a9.125 9.125 0 001.991-2.948" />
                </g>
              </svg>
              <h4 className="h4 mb-2">IT Partnerskab</h4>
              <p className="text-lg text-gray-400 text-center">Løsning af og rådgivning om ad hoc IT relaterede udfordringer</p>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}
