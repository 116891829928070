import React from "react";
import { FaEnvelope, FaLinkedin, FaGithub } from "react-icons/fa"; // Import icons

const Sidebar: React.FC = () => {
    return (
        <div className="fixed right-0 top-1/2 transform -translate-y-1/2">
            <div className="w-16 h-[25vh] bg-white border-l border border-gray-300 flex flex-col items-center justify-center space-y-16">
                {/* Email Icon */}
                <a
                    href="mailto:chrisdalbertsen@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaEnvelope className="w-7 h-7 text-gray-800 hover:text-gray-600" />
                </a>

                {/* LinkedIn Icon */}
                <a
                    href="https://www.linkedin.com/in/chris-albertsen/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaLinkedin className="w-7 h-7 text-gray-800 hover:text-gray-600" />
                </a>

                {/* GitHub Icon */}
                <a
                    href="https://github.com/ChrisAlbertsen"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaGithub className="w-7 h-7 text-gray-800 hover:text-gray-600" />
                </a>
            </div>
        </div>
    );
};

export default Sidebar;
